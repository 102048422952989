import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
  InteractionItem,
} from 'chart.js'
import {
  Bar,
  Chart,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent, Line,
} from 'react-chartjs-2'
import { useEffect, useState } from 'react'
import React, { MouseEvent, useRef } from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid' // Grid version 1
import Grid2 from '@mui/material/Unstable_Grid2' // Grid version 2
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import PersonIcon from '@mui/icons-material/Person'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import FlakyIcon from '@mui/icons-material/Flaky'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import PeopleIcon from '@mui/icons-material/People'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import CircularProgress from '@mui/material/CircularProgress'
import { default as MToolTip } from '@mui/material/Tooltip'
import { Box, Container, Fade } from '@mui/material'
import { ReactComponent as LoadingLogo } from './logo.svg'
import { useGlitch } from 'react-powerglitch'

import { ColorRing, Vortex } from 'react-loader-spinner'
import Typography from '@mui/material/Typography'
import SvgIcon from '@mui/material/SvgIcon'
import Loading from './Loading'
import TextField from '@mui/material/TextField';
import {ApiPromise, WsProvider} from "@polkadot/api";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import  { Tooltip as MTooltip }  from '@mui/material/'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
)

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  color: white;
  & .Mui-selected {
    color: rgba(230, 0, 122, 1);
    background-color: rgba(230, 0, 122, 0);
    border-color: rgba(230, 0, 122, 1);
  }
  //
`

export const StyledToggleButton = styled(ToggleButton)`
  background-color: rgba(230, 0, 122, 0.25);
  border-color: rgba(230, 0, 122, 0.25);
  border-radius: 8px;
  font-size: 8px;
  color: white;
  box-shadow: 0 3px 5px 2px rgba(230, 0, 122, 0.15);
`

const footer = (tooltipItems: any): any => {
  let date;

  tooltipItems.forEach(function(tooltipItem: any) {
    date = tooltipItem.dataset.date[tooltipItem.parsed.x]
  });
  return date
};

export const options = {
  plugins: {
    legend: {
      labels: {
        color: 'rgba(255,255,255,0.9)',
      },
    },
    tooltip: {
      callbacks: {
        footer: footer,
      }
    }
  },
  responsive: true,
  interaction: {
    intersect: true,
    mode: 'index',
  },
  labelColor: 'rgba(255,255,255,0.9)',
  scales: {
    x: {
      stacked: true,
      grid: {
        tickColor: '#FF33AD',
        z: 0,
      },
      border: {
        color: '#FF33AD',
      },
      title: {
        color: '#FF33AD',
      },
      ticks: {
        textStrokeColor: '#FF33AD',
        color: '#FF33AD',
        backdropPadding: 10,
        backdropColor: '#FF33AD',
      },
    },
    y: {
      border: {
        color: '#FF33AD',
      },
      stacked: true,
      title: {
        color: '#FF33AD',
      },
      ticks: {
        color: '#FF33AD',
        textStrokeColor: '#FF33AD',
      },
    },
  },
}



export function ScalingChart({}: {}): JSX.Element {
  const [validatorCount, setValidatorCount] = useState<any>(0);
  const [targetAmount, setTargetAmount] = useState<any>(600)

  const [increaseAmount, setIncreaseAmount] = useState<number>(4);
  const [eras, setEras] = useState<any>([]);

  const [lastEra, setLastEra] = useState<any>();
  const [lastTotalReward, setLastTotalReward] = useState<any>();
  const [lastTotalStake, setLastTotalStake] = useState<any>();

  const [targetDate, setTargetDate] = useState<any>('')
  const [startDate, setStartDate] = useState<any>(dayjs())


  const [toggle, setToggle] = useState('all')
  const [countToggle, setCountToggle] = useState('amount')


  const [chartData, setChartData] = useState({ datasets: [] })
  const chartRef = useRef<ChartJS>(null)



  const glitch = useGlitch()



  useEffect(() => {
    if ( !increaseAmount || Number(increaseAmount) < 0 || Number(increaseAmount) > 1000) {
      console.log('Not valid')
      return;
    }
    const labels: string[] = []
    const era = [];
    let valCount = Number(validatorCount) | 0;
    let date = startDate
    console.log(valCount)
    console.log(`validator count: ${validatorCount}`)
    console.log(`target amount: ${targetAmount}`)
    console.log(`increase amount: ${increaseAmount}`)
    let e = lastEra;
    let count = 0;
    for (let i = valCount; i <= targetAmount; i += Number(increaseAmount) ){
      era.push({
        era: e,
        validatorCount: i,
        avgReward: lastTotalReward / i,
        avgStake: lastTotalStake / i,
        date: date.add(count, 'day')
      })
      e++
      count++
    }
    console.log(`count: ${count}`)
    date = date.add(count, 'day');
    setTargetDate(date.format('DD/MM/YYYY'))
    for (const e of era) {
      labels.push(`Era ${e?.era?.toString()}`)
    }
    const data = {
      labels,
      datasets: [
        {
          label: 'Validator Set Size',
          data: era.map((era: any) => era.validatorCount),
          backgroundColor: 'rgba(230,0,122,0.25)',
          borderColor: 'rgba(230,0,122,1)',
          borderWidth: 1,
          date: era.map((era: any) => era.date),
        },
        {
          label: '100% Commission Reward',
          data: era.map((era: any) => era.avgReward),
          backgroundColor: 'rgba(68,34,153,0.35)',
          borderColor: 'rgba(109,58,238,1)',
          borderWidth: 1,
          date: era.map((era: any) => era.date),
        },
        {
          label: '3% Commission Reward',
          data: era.map((era: any) => era.avgReward * 0.03),
          backgroundColor: 'rgba(72,204,129,0.35)',
          borderColor: 'rgba(86,243,154,1)',
          borderWidth: 1,
          date: era.map((era: any) => era.date),
        },
        {
          label: 'Min Stake',
          data: era.map((era: any) => era.avgStake),
          backgroundColor: 'rgba(0,148,212,0.35)',
          borderColor: 'rgba(0,178,255,1)',
          borderWidth: 1,
          date: era.map((era: any) => era.date),
        },
      ],
    }
    // @ts-ignore
    setChartData(data)
  }, [validatorCount, targetAmount, increaseAmount, startDate])

  // @ts-ignore
  useEffect(() => {
    const fetchChainInfo = async() => {

      const wsProvider = new WsProvider('wss://rpc.polkadot.io');
      const api = await ApiPromise.create({provider: wsProvider});
      // @ts-ignore
      const currentEra = (await api.query.staking.activeEra()).toJSON().index;
      const lastEra = currentEra - 1;
      // @ts-ignore
      const lastReward = (await api.query.staking.erasValidatorReward(lastEra)) / Math.pow(10,10)
      // @ts-ignore
      const lastTotalStake = (await api.query.staking.erasTotalStake(lastEra)) / Math.pow(10,10)
      const validatorCount = await api.query.staking.validatorCount();

      const avgReward = lastReward / Number(validatorCount)
      const avgStake = lastTotalStake / Number(validatorCount)


      setValidatorCount(Number(validatorCount))
      setLastEra(lastEra)
      setLastTotalReward(lastReward)
      setLastTotalStake(lastTotalStake)


    };



    fetchChainInfo()
        .then(()=>{
      console.log('set data')
      console.log(chartData)
    }).catch(console.error)
  }, [])

  return (
    <>
      {lastEra  ? (
        <>
          <Grid container direction={'row'} spacing={0} sx={{pt:2, pb: 2}}>
            <Grid item xs={6} md={2}>
              <MTooltip title="The starting validator set size. This will default to the current chain's set size until otherwise specified.">
              <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <Typography>Set Size: {validatorCount}</Typography>
                <HelpOutlineIcon color={'primary'}/>
              </Grid>
              </MTooltip>

            </Grid>

            <Grid item xs={6} md={2}>

              <MTooltip title="The number of validators to increase the set size to.">
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <Typography>Target Set Size: {targetAmount}</Typography>
                  <HelpOutlineIcon color={'primary'}/>
                </Grid>
              </MTooltip>

            </Grid>
            <Grid item xs={12} md={2}>


              <MTooltip title="The last on-chain era. The last eras rewards and minimum stake amounts are from this era.">
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <Typography>Last Era: {lastEra}</Typography>
                  <HelpOutlineIcon color={'primary'}/>
                </Grid>
              </MTooltip>


            </Grid>


            <Grid item xs={6} md={3}>

              <MTooltip title="The minimum stake needed to be an active validator, as of the last era.">
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <Typography>Last Min Stake: ~{(lastTotalStake / validatorCount).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })} DOT</Typography>
                  <HelpOutlineIcon color={'primary'}/>
                </Grid>
              </MTooltip>

            </Grid>
            <Grid item xs={6} md={3}>

              <MTooltip title="The estimate of when the validator set will increase to the target set size, based on how many validators the set increases per era.">
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <Typography>Target Date: {targetDate}</Typography>
                  <HelpOutlineIcon color={'primary'}/>
                </Grid>
              </MTooltip>


            </Grid>

          </Grid>

          <Grid container spacing={0} sx={{pt:2, pb: 2}}>
          <Grid item xs={6} md={3}>

            <MTooltip title="The average daily reward for a validator in the set with 100% commission. This number fluctuates for validators based on how often they are a para-validator or not.">
              <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <Typography>Avg 100% Commision Daily Reward: ~{(lastTotalReward / validatorCount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })} DOT</Typography>
                <HelpOutlineIcon color={'primary'}/>
              </Grid>
            </MTooltip>


          </Grid>
          <Grid item xs={6} md={3}>

            <MTooltip title="The average daily reward for a validator in the set with 3% commission. This number fluctuates for validators based on how often they are a para-validator or not.">
              <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <Typography>Avg 3% Commmission Daily Reward: ~{((lastTotalReward / validatorCount) * 0.03).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })} DOT</Typography>
                <HelpOutlineIcon color={'primary'}/>
              </Grid>
            </MTooltip>


          </Grid>
            <Grid item xs={6} md={3}>

              <MTooltip title="The average daily reward for a validator in the set with 100% commission. This number fluctuates for validators based on how often they are a para-validator or not. This assumes the validator is in the set 24/7">
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <Typography>Avg 100% Commission Monthly Reward: ~{((lastTotalReward / validatorCount) * 30).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })} DOT</Typography>
                  <HelpOutlineIcon color={'primary'}/>
                </Grid>
              </MTooltip>


            </Grid>
            <Grid item xs={6} md={3}>

              <MTooltip title="The average daily reward for a validator in the set with 3% commission. This number fluctuates for validators based on how often they are a para-validator or not. This assumes the validator is in the set 24/7">
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <Typography>Avg 3% Commission Monthly Reward: ~{((lastTotalReward / validatorCount) * 0.03 * 30).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })} DOT</Typography>
                  <HelpOutlineIcon color={'primary'}/>
                </Grid>
              </MTooltip>


            </Grid>
          </Grid>

          <Grid container spacing={0} sx={{pt:2, pb: 3}}>

            <Grid item xs={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
              sx={{backdropFilter: 'blur(10px)'}}/>
            </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                  type="number"
                  id="outlined-controlled"
                  label="Validator Set Size"
                  value={validatorCount}
                  InputProps={{
                    style: {width: "150px"},
                    type: 'number',
                    inputProps: {
                      max: 1000, min: 0
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                    if (event.target.value){
                      setValidatorCount(Number(event.target.value));
                    } else {
                      setValidatorCount(0);
                    }
                  }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                  type="number"
                  id="outlined-controlled"
                  label="Target Validator Set Size"
                  value={targetAmount}
                  InputProps={{
                    type: 'number',
                    inputProps: {
                      max: 1000, min: validatorCount
                    },
                    style: {width: "150px"}
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value){
                      setTargetAmount(Number(event.target.value));
                    } else {
                      setTargetAmount(0);
                    }
                  }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                  type="number"
                  id="outlined-controlled"
                  label="Set Size Increase Per Era"
                  value={increaseAmount}
                  InputProps={{
                    type: 'number',
                    inputProps: {
                      max: 1000, min: 1
                    },
                    style: {width: "150px"}
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value){
                      setIncreaseAmount(Number(event.target.value));
                    } else {
                      setIncreaseAmount(Number(0));
                    }

                  }}
              />
            </Grid>
          </Grid>
          <Box sx={{height:'90vh', width: '80vw'}}>
          <Bar
              //@ts-ignore
            options={options}
            data={chartData}
          />
          </Box>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  )
}
