import React from 'react'
import './App.css'
import { ScalingChart } from './components/Chart'
import { Routes, Route } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'
import { theme } from './themes/theme'


function App() {
    return (
        <div className="All">
            <ThemeProvider theme={theme}>
                <div className="App">
                    <Routes>
                        <Route path={`/val-scaling`} element={<ScalingChart/>} />
                        <Route path="/" element={<ScalingChart />} />
                    </Routes>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default App
